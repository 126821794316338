<template>
  <v-container  class="text-center ">
        <v-row align="center" justify="center" class="fill-height my-5 py-5">
          <v-col cols="12">
                <v-row align="center" justify="center" class="fill-height my-5 py-5" >
                  <v-col cols="10" lg="6" v-if="code">
                    <h2 class="brandon mb-3">Mostra in cassa il QrCode <br> per ricevere il tuo premio</h2>
                    <v-img width="80%" class="mx-auto" :src="'https://api.qrserver.com/v1/create-qr-code/?size=500x500&format=png&color=5b2311&bgcolor=FFF&margin=50&data='+code"></v-img>
                    <p class="text-uppercase">{{code}}</p>
                  </v-col>
                  <v-col v-else>
                    <h3>No code found back to app</h3>
                    <v-card max-width="130px" class="mx-auto rounded-card" :elevation="4" target="_blank" :href="deeplink">
                     <v-img class="mx-auto" :src="require('@/assets/images/app-logo.png')"></v-img>
                    </v-card>
                  </v-col>
                </v-row>
          </v-col>
        </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'Qrcode',
  data: () => ({
    title: process.env.VUE_APP_BASE_NAME,
    deeplink: process.env.VUE_APP_DEEP_LINK,
    code: null,
    download: {
      android: "https://play.google.com/store/apps/details?id=com.atono.venchi",
      ios: "https://apps.apple.com/it/app/venchi-cioccolato-gelato/id1473955521"
    }
  }),
  mounted(){
     if(this.$route.params.code){
          this.code = this.$route.params.code
     }
  }
};
</script>

<style scoped>
.logo{
  max-width: 190px;
  position: relative;
}
.v-card.rounded-card{
    border-radius:30px;
}

</style>
